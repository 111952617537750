import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import ProductsSlider from "~components/products/productsSlider";
import "../styles/pages/_lp.scss";
import bike from "../images/lp/TSD_Dance_Services_Start.png";
import surang from "../images/lp/TSD_Design_Services_Hand.png";

import sample1 from "../images/lp/TSD_Design_Example_Bag.jpg";
import sample10 from "../images/lp/TSD_Design_Example_Box1.jpg";
import sample2 from "../images/lp/TSD_Design_Example_Mug.jpg";
import sample3 from "../images/lp/TSD_Design_Example_Presentation.jpg";
import sample4 from "../images/lp/TSD_Design_Example_Puccini_Ad.jpg";
import sample5 from "../images/lp/TSD_Design_Example_Report.jpg";
import sample6 from "../images/lp/TSD_Design_Examples_Biz_Cards.jpg";
import sample7 from "../images/lp/TSD_Design_Examples_Hat.jpg";
import sample8 from "../images/lp/TSD_Design_Examples_Made_With_Local.jpg";
import sample9 from "../images/lp/TSD_Design_Examples_Pizza.jpg";

var settings = {
  centerMode: false,
  infinite: true,
  slidesToShow: 4,
  dots: false,
  arrows: true,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500,
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const PrePaidDesignServices = () => (
  <Layout type="marketin-stuck-lp template-lp">
    <SEO
      title="Pre-Paid Design Services to Suit Every Budget"
      description="Projects big and small, we do it all! With a wide variety of talented designers on our team, and scalable hours, our pre-paid design services help you stay on target and budget."
    />

    <section className="pre-paid-design-hero">
      <div className="container">
        <div className="content-wrapper">
          <h1>Design is Everywhere.</h1>
          <div className="copy-div">
            <h2>
              And almost every part of your business needs a designer’s touch.
            </h2>
            <p>
              Great designers are visual communicators, and they apply their
              technical skills to help get your [message/feeling/brand/point]
              across in the most simple and effective manner.
            </p>
            <img src={surang} />
          </div>
        </div>
      </div>
    </section>
    <section className="website-lp-shout pre-paid-design-section">
      <div className="container">
        <div className="content-wrapper">
          <h2>Designers for every occasion.</h2>
          <p>
            Web graphics, motion graphics, packaging, marketing collateral,
            presentations/decks, annual and quarterly reports, sell sheets, POS
            and retail displays, menus, signage – our talented, deep team of
            visual artists are here and excited to help out with your design
            project – whatever it is.
          </p>
          <p className="btn-cta-wrapper">
            <strong>
              <Link to="#TSD-Design_Packages" className="lp-btn red-btn">
                Can we break out our tool belts?
              </Link>
            </strong>
          </p>
        </div>
      </div>
    </section>
    <section className="pre-paid-design-sample">
      <div className="">
        <h2>Bite-sized Samples</h2>
        <Slider {...settings} className="">
          <div className="slider-item">
            <img src={sample7} alt="" className="image7" />
            <img src={sample4} alt="" className="image4" />
          </div>
          <div className="slider-item">
            <img src={sample8} alt="" className="image8" />
            <img src={sample3} alt="" className="image3" />
          </div>
          <div className="slider-item">
            <img src={sample2} alt="" className="image2" />
            <img src={sample5} alt="" className="image5" />
          </div>
          <div className="slider-item">
            <img src={sample9} alt="" className="image9" />
            <img src={sample1} alt="" className="image1" />
          </div>
          <div className="slider-item">
            <img src={sample10} alt="" className="image10" />
            <img src={sample6} alt="" className="image6" />
          </div>
        </Slider>
      </div>
    </section>
    <section className="ms-lp-products" id="TSD-Design_Packages">
      <div className="container">
        <div className="content-wrapper">
          <h2 className="text-center">What Can We Get You Today?</h2>
        </div>
        <ProductsSlider>
          <div title="SMALL FRIES" price="$960" id="design-small">
            <p className="product-slider-subtitle">10 Design Hours</p>
            <p>
              This is perfect if you know you are going to need help with a
              bunch of small requests like posters, postcards, business
              cards/stationary, sell sheets, signage, in-store promotional
              material, etc.. Or maybe something a little more intensive like
              creating a brochure or a buyer presentation.
            </p>
          </div>
          <div title="MEDIUM FRIES" price="$2,280" id="design-medium">
            <p className="product-slider-subtitle">25 Design Hours</p>
            <p>
              This is what you want when you are planning out a sales deck,
              investor presentation, quarterly/annual report or a longer form
              newsletter. This is also smart when you have regular graphic
              design projects and the discounted hourly rate helps your budget
              go further.
            </p>
          </div>
          <div title="LARGE FRIES" price="$4,332" id="design-large">
            <p className="product-slider-subtitle">50 Design Hours</p>
            <p>
              Maybe you need help developing a packaging concept, or
              fixing/updating some old packaging? What about planning your
              quarterly drop for your retail stores (new promotional material)?
              Maybe it is a larger project that needs some heavy hours, or maybe
              you just need a lot of design support for your social media
              marketing,
            </p>
          </div>
          <div
            title="1 Hour Consultation + Design Brief"
            price="$125"
            id="design-consultation"
          >
            <p>
              The big reason to do this [almost every time you have a project]
              is to get a design brief that will save you hours/money in the
              long run. That brief is also something you can take to other
              designers for really accurate quotes. More importantly, there is
              no ambiguity, the design team and you are on the same page for
              what the deliverable(s) is/are, how this project will be produced
              and used and what design skills are needed to pull it off.
            </p>
          </div>
        </ProductsSlider>
      </div>
    </section>
    <section className="da-lp-hero pre-paid-design-footer">
      <img src={bike} />
      <div className="container">
        <div className="content-wrapper">
          <h2>Not Sure Where To Start?</h2>
          <p>
            If you are completely unsure of how many hours you need but you’re
            not interested in a complete design brief, fill out this super fancy
            design quote form and our team will get back to you with a custom
            quote.
          </p>
          <p className="btn-cta-wrapper">
            <strong>
              <a
                target="_blank"
                href="https://hsmgsurveys.typeform.com/to/IOjSBmXN"
                className="lp-btn white-btn-blue-text"
              >
                Yes, I Like Fancy Forms
              </a>
            </strong>
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default PrePaidDesignServices;
